import tinycolor from 'tinycolor2';
import keys from '../utils/keys';
import { isObject as isObj } from '../utils/object';
import { isArray as isArr } from '../utils/array';
import { isNumber as isNumb } from '../utils/number';
import * as E from '../typing/enums';

let validatorsErrorMsg = [];

export const isNumber = (value, key) => {
  const isValid = isNumb(value);
  if (!isValid) {
    validatorsErrorMsg.push(`${key} = [${value}] should be a number`);
  }

  return isValid;
};

export const isArray = (value, key) => {
  const isValid = isArr(value);
  if (!isValid) {
    validatorsErrorMsg.push(`${key} = [${value}] should be an array`);
  }

  return isValid;
};

export const isStr = value => typeof value === 'string';

export const isObject = (value, key) => {
  const isValid = isObj(value);
  if (!isValid) {
    validatorsErrorMsg.push(`${key} = [${value}] should be an object`);
  }

  return isValid;
};

export const isMediaAssets = items => {
  const validItems = items.filter(item => {
    if (isStr(item)) {
      return true;
    } else if (isObj(item)) {
      if (!item.tag && !item.publicId) {
        validatorsErrorMsg.push(
          `mediaAsset should have either a tag or a publicId defined: ${JSON.stringify(item)}`
        );

        return false;
      } else if (item.tag && item.publicId) {
        validatorsErrorMsg.push(
          `mediaAsset should have either a tag or a publicId defined: ${JSON.stringify(item)}`
        );

        return false;
      } else if (item.publicId) {
        if (!isStr(item.publicId)) {
          validatorsErrorMsg.push(`publicId should be a string: ${JSON.stringify(item)}`);

          return false;
        }
        if (item.resourceType) {
          if (!isAssetType(item.resourceType, 'resourceType')) {
            return false;
          }
        }
        if (item.mediaType) {
          if (!isMediaSymbolTypes(item.mediaType, 'mediaType')) {
            return false;
          }
        }
      } else if (item.tag) {
        if (!isStr(item.tag)) {
          validatorsErrorMsg.push(`tag should be a string: ${JSON.stringify(item)}`);

          return false;
        }
      }
      if (item.mediaType) {
        return isMediaSymbolTypes(item.mediaType, 'mediaType');
      }
      if (item.transformation) {
        // if (!isObj(item.transformation)) {
        //   validatorsErrorMsg.push(
        //     `transformation should be an object: ${JSON.stringify(item)}`
        //   );
        //   return false;
        // }
        // if (!isTransformation(item.transformation, 'transformation')) {
        //   return false;
        // }
      }
      if (item.thumbnailTransformation) {
        // if (!isObj(item.thumbnailTransformation)) {
        //   validatorsErrorMsg.push(
        //     `thumbnailTransformation should be an object on media ${JSON.stringify(
        //       item
        //     )}`
        //   );
        //   return false;
        // }
        // if (!isTransformation(item.thumbnailTransformation, 'mediaAssets')) {
        //   return false;
        // }
      }
    } else {
      validatorsErrorMsg.push(
        `an error with the definition of your media asset: ${JSON.stringify(item)}`
      );

      return false;
    }

    return true;
  });

  return validItems.length === items.length;
};

export const isString = (value, key) => {
  const isValid = typeof value === 'string';
  if (!isValid) {
    validatorsErrorMsg.push(`${key} = [${value}] should be a string`);
  }

  return isValid;
};

export const isNotEmpty = (value, key) => {
  const isValid = value ? value.trim() !== '' : false;
  if (!isValid) {
    validatorsErrorMsg.push(`${key} is empty. It should have a value`);
  }

  return isValid;
};

export const isElement = (selectorOrDom, key) => {
  try {
    return selectorOrDom instanceof HTMLElement ? true : !!document.querySelector(selectorOrDom);
  } catch (e) {
    validatorsErrorMsg.push(
      `${key} = [${selectorOrDom}]. It should be a css selector (eg. 'id' or 'class') or a dom element`
    );

    return false;
  }
};

export const isGreaterThan = (value, greaterThan, key, hideError) => {
  const isValid = value > greaterThan;
  if (!isValid && !hideError) {
    validatorsErrorMsg.push(`${key} = [${value}].  It should be >= ${greaterThan}`);
  }

  return isValid;
};

export const isLessThan = (value, lessThan, key) => {
  const isValid = !isGreaterThan(value, lessThan, key, true);
  if (!isValid) {
    validatorsErrorMsg.push(`${key} = [${value}].  It should be <= ${lessThan}`);
  }

  return isValid;
};

export const isNotNegative = (value, key) => {
  const isValid = isGreaterThan(value, -1, key, true);
  if (!isValid) {
    validatorsErrorMsg.push(`${key} = [${value}].  It should be >= 0`);
  }

  return isValid;
};

export const isPositive = (value, key) => {
  const isValid = isGreaterThan(value, 0, key, true);
  if (!isValid) {
    validatorsErrorMsg.push(`${key} = [${value}].  It should be > 0`);
  }

  return isValid;
};

export const isInteger = (value, key) => {
  const isValid = Number.isInteger(value);
  if (!isValid) {
    validatorsErrorMsg.push(`${key} = [${value}].  It should be an Integer`);
  }

  return isValid;
};

export const isBoolean = (value, key) => {
  const isValid = typeof value === 'boolean';
  if (!isValid) {
    validatorsErrorMsg.push(`${key} = [${value}]. It should be set to true or false`);
  }

  return isValid;
};
const isValidEnum = (value, enumType, key) => {
  const enumValues = Object.values(enumType);
  const isValid = !!enumValues.find(val => val === value);
  if (!isValid) {
    validatorsErrorMsg.push(
      `${key} = [${value}]. This parameter supports only the following values: ${enumValues.join(
        '|'
      )}`
    );
  }

  return isValid;
};
const isValidEnumOrBoolean = (value, enumType, key) => {
  // if value is boolean, convert ot string to support older version
  const stringValue = typeof value === 'boolean' ? value.toString() : value;

  return isValidEnum(stringValue, enumType, key);
};

export const isColor = (value, key) => {
  if (!tinycolor(value).isValid()) {
    validatorsErrorMsg.push(
      `${key} = [${value}]. It should be a valid color definition (HEX, RGB, HLS, etc.)`
    );

    return false;
  }

  return true;
};

export const isPreload = preload => {
  const key = 'preload';
  let isValid = true;
  preload.forEach(value => {
    if (isString(value, key)) {
      if (!isValidEnum(value, E.PreloadTypes, key)) {
        isValid = false;
      }
    } else {
      isValid = false;
    }
  });

  return isValid;
};
const isAspectRatio = (value, key) => {
  const ASPECT_RATIO_ENUM_VALUES = ['square'];
  const ASPECT_RATIO_REGEX = /^[1-9]\d*:[1-9]\d*$/;

  if (ASPECT_RATIO_ENUM_VALUES.includes(value) || ASPECT_RATIO_REGEX.test(value)) {
    return true;
  }

  validatorsErrorMsg.push(
    `${key} = '${value}'. This parameter supports only specific format: 'width:height' (where width/height is integer) or one of enum values: ${ASPECT_RATIO_ENUM_VALUES.map(
      v => `'${v}'`
    ).join(', ')}`
  );

  return false;
};
const isAssetType = (value, key) => isValidEnum(value, E.AssetType, key);
const isPosition = (value, key) => isValidEnum(value, E.CarouselLocation, key);
const isNavigation = (value, key) => isValidEnum(value, E.Navigation, key);
const isButtonShape = (value, key) => isValidEnum(value, E.ButtonShape, key);
const isGalleryNavigationPosition = (value, key) =>
  isValidEnum(value, E.GalleryNavigationPosition, key);
const isMediaSymbolPosition = (value, key) => isValidEnum(value, E.MediaSymbolPosition, key);
const isMediaSymbolTypes = (value, key) => isValidEnum(value, E.MediaSymbolTypes, key);
const isMediaSymbolShape = (value, key) => isValidEnum(value, E.MediaSymbolShape, key);
const isSelectedStyles = (value, key) => isValidEnum(value, E.SelectedStyles, key);
const isSelectedBorderPosition = (value, key) => isValidEnum(value, E.SelectedBorderPosition, key);
const isDirection = (value, key) => isValidEnum(value, E.Direction, key);
const isCarouselStyle = (value, key) => isValidEnum(value, E.CarouselStyle, key);
const isPerView = (value, key) => isGreaterThan(value, -1, key);
const isButtonSize = (value, key) => isGreaterThan(value, 16, key);
const isLessThan5 = (value, key) => isLessThan(value, 5, key);
const isZoomViewerPosition = (value, key) => isValidEnum(value, E.ZoomViewerPosition, key);
const isIndicatorShape = (value, key) => isValidEnum(value, E.IndicatorShape, key);
const isSkin = (value, key) => isValidEnum(value, E.Skin, key);
const isTransition = (value, key) => isValidEnum(value, E.Transition, key);
const isCrop = (value, key) => isValidEnum(value, E.Crop, key);
const isZoomTrigger = (value, key) => isValidEnum(value, E.ZoomTrigger, key);
const isZoomType = (value, key) => isValidEnum(value, E.ZoomType, key);
const isSpinAnimation = (value, key) => isValidEnum(value, E.SpinAnimation, key);
const isSpinDirection = (value, key) => isValidEnum(value, E.SpinDirection, key);
const isSort = (value, key) => isValidEnum(value, E.Sort, key);
const isTipPosition = (value, key) => isValidEnum(value, E.TipPosition, key);
const isShowTip = (value, key) => isValidEnum(value, E.TipShow, key);
const isZoomPopupShape = (value, key) => isValidEnum(value, E.ZoomPopupShape, key);
const isTransformation = (value, key) => {
  let isValid = true;
  if (value.crop) {
    if (!isCrop(value.crop, key)) {
      return false;
    }
  }
  if (value.transformation) {
    if (!isArray(value.transformation, 'transformation.transformation')) {
      return false;
    }
  }
  if (value.width || value.height) {
    isValid = false;
    validatorsErrorMsg.push(`'${key}' currently has width or height defined. Please remove`);
  }

  return isValid;
};
const isControls = (value, key) => isValidEnumOrBoolean(value, E.Controls, key);
const isDisplayMode = (value, key) => isValidEnum(value, E.DisplayMode, key);
const isLoaderStyle = (value, key) => isValidEnum(value, E.LoaderStyle, key);
const validatorZoomProps = {
  _validator: true,
  _type: {},
  _props: keys('ZoomConfig'),
  level: [isNumber, isNotNegative],
  steps: [isInteger, isNotNegative, isLessThan5],
  stepLimit: [isBoolean],
  viewerPosition: [isString, isZoomViewerPosition],
  viewerOffset: [isNumber, isNotNegative],
  viewerRadius: [isNumber, isNotNegative],
  viewerZIndex: [isNumber],
  showLens: [isBoolean],
  lensBorderColor: [isString, isColor],
  lensBorderWidth: [isNumber, isNotNegative],
  lensColor: [isString, isColor],
  lensOpacity: [isNumber, isNotNegative],
  lensRadius: [isNumber, isNotNegative],
  lensShadow: [isBoolean],
  trigger: [isString, isZoomTrigger],
  container: [isString, isElement],
  showTip: [isString, isShowTip],
  tipText: [isString],
  tipTouchText: [isString],
  tipPosition: [isString, isTipPosition],
  type: [isZoomType]
};
const validatorSpinProps = {
  _validator: true,
  _type: {},
  _props: keys('SpinProps'),
  animate: [isSpinAnimation],
  spinDirection: [isSpinDirection],
  disableZoom: [isBoolean],
  tipText: [isString],
  tipTouchText: [isString],
  tipPosition: [isString, isTipPosition],
  showTip: [isString, isShowTip]
};
const validatorsThumbnailProps = {
  _validator: true,
  _props: keys('ThumbnailConfigProps'),
  _type: {},
  transformation: [isObject, isTransformation],
  spacing: [isNumber, isNotNegative],
  gutter: [isNumber, isNotNegative],
  perView: [isNumber, isPerView],
  width: [isNumber, isNotNegative],
  height: [isNumber, isNotNegative],
  borderWidth: [isNumber, isNotNegative],
  borderColor: [isString, isColor],
  radius: [isNumber, isNotNegative],
  navigationBorderColor: [isString, isColor],
  navigationBorderWidth: [isNumber, isNotNegative],
  navigationIconColor: [isString, isColor],
  navigationColor: [isString, isColor],
  navigationSize: [isNumber, isButtonSize],
  navigationShape: [isString, isButtonShape],
  navigationFloat: [isBoolean],
  mediaSymbolPosition: [isString, isMediaSymbolPosition],
  mediaSymbolType: [isString, isMediaSymbolTypes],
  mediaSymbolShape: [isString, isMediaSymbolShape],
  mediaSymbolColor: [isString, isColor],
  mediaSymbolOpacity: [isNumber],
  mediaSymbolIconShadow: [isBoolean],
  mediaSymbolIconColor: [isString, isColor],
  mediaSymbolSize: [isNumber],
  selectedStyle: [isString, isSelectedStyles],
  selectedBorderPosition: [isString, isSelectedBorderPosition],
  selectedBorderColor: [isString, isColor],
  selectedBorderWidth: [isNumber, isNotNegative],
  selectedBorderOpacity: [isNumber, isNotNegative],
  selectedGradientStart: [isString, isColor],
  selectedGradientEnd: [isString, isColor],
  selectedGradientDirection: [isString, isDirection],
  selectedGradientOpacity: [isNumber, isNotNegative]
};
const validatorsIndicatorProps = {
  _validator: true,
  _props: keys('IndicatorProps'),
  _type: {},
  selectedColor: [isString, isColor],
  color: [isString, isColor],
  size: [isNumber, isNotNegative],
  spacing: [isNumber],
  shape: [isString, isIndicatorShape]
};
const validatorsZoomPopupProps = {
  _validator: true,
  _props: keys('ZoomPopupProps'),
  _type: {},
  buttonShape: [isString, isZoomPopupShape],
  buttonIconColor: [isString, isColor],
  buttonColor: [isString, isColor],
  buttonSize: [isNumber, isNotNegative],
  zIndex: [isNumber],
  backdropOpacity: [isNumber, isNotNegative],
  backdropColor: [isString, isColor]
};
const validatorsDisplayProps = {
  _validator: true,
  _props: keys('DisplayProps'),
  _type: {},
  mode: [isDisplayMode],
  spacing: [isNumber, isNotNegative],
  columns: [isNumber, isPositive, isInteger]
};

const validators = {
  logErrors: [isBoolean],
  id: [isString],
  skin: [isString, isSkin],
  startIndex: [isNumber, isNotNegative],
  cloudName: [isString, isNotEmpty],
  privateCdn: [isString],
  secureDistribution: [isString],
  cname: [isString],
  sort: [isSort],
  focus: [isBoolean],
  cdnSubdomain: [isString],
  container: [isElement],
  thumbnailContainer: [isString, isElement],
  aspectRatio: [isString, isAspectRatio],
  transition: [isString, isTransition],
  preventPageScroll: [isBoolean],
  analytics: [isBoolean],
  placeholderImage: [isBoolean],
  preload: [isArray, isPreload],
  selectedIndex: [isNumber, isNotNegative],
  mediaAssets: [isArray, isMediaAssets],
  transformation: [isObject, isTransformation],
  borderColor: [isString, isColor],
  borderWidth: [isNumber, isNotNegative],
  radius: [isNumber, isNotNegative],
  carouselLocation: [isString, isPosition],
  carouselStyle: [isString, isCarouselStyle],
  carouselOffset: [isNumber],
  thumbnailProps: validatorsThumbnailProps,
  indicatorProps: validatorsIndicatorProps,
  zoomPopupProps: validatorsZoomPopupProps,
  queryParam: [isString],
  viewportBreakpoints: {
    _validator: true,
    _type: [],
    _props: keys('ViewportBreakpointConfigProps'),
    breakpoint: [isNotNegative],
    transformation: [isObject, isTransformation],
    aspectRatio: [isString, isAspectRatio],
    borderColor: [isString, isColor],
    borderWidth: [isNumber, isNotNegative],
    radius: [isNumber, isNotNegative],
    carouselLocation: [isString, isPosition],
    carouselStyle: [isString, isCarouselStyle],
    carouselOffset: [isNumber],
    navigation: [isString, isNavigation],
    navigationPosition: [isString, isGalleryNavigationPosition],
    navigationOffset: [isNumber],
    navigationButtonProps: {
      _validator: true,
      _props: keys('NavigationButtonProps'),
      _type: {},
      shape: [isString, isButtonShape],
      iconColor: [isString, isColor],
      color: [isString, isColor],
      size: [isNumber]
    },
    thumbnailProps: validatorsThumbnailProps,
    indicatorProps: validatorsIndicatorProps,
    zoomPopupProps: validatorsZoomPopupProps,
    zoomProps: validatorZoomProps,
    spinProps: validatorSpinProps
  },
  navigation: [isString, isNavigation],
  navigationPosition: [isString, isGalleryNavigationPosition],
  navigationOffset: [isNumber],
  navigationButtonProps: {
    _validator: true,
    _type: {},
    _props: keys('NavigationButtonProps'),
    shape: [isString, isButtonShape],
    color: [isString, isColor],
    bgColor: [isString, isColor],
    size: [isNumber]
  },
  zoom: [isBoolean],
  zoomProps: validatorZoomProps,
  themeProps: {
    _validator: true,
    _type: {},
    _props: keys('ThemeConfig'),
    primary: [isString, isColor],
    onPrimary: [isString, isColor],
    active: [isString, isColor],
    onActive: [isString, isColor]
  },
  spinProps: validatorSpinProps,
  videoProps: {
    _validator: true,
    _type: {},
    _props: keys('VideoConfig'),
    autoplay: [isBoolean],
    loop: [isBoolean],
    controls: [isControls],
    sound: [isBoolean],
    version: [isString],
    playerType: [isString]
  },
  tipProps: {
    _validator: true,
    _type: {},
    _props: keys('TipProps'),
    textColor: [isString, isColor],
    color: [isString, isColor],
    radius: [isNumber, isNotNegative],
    opacity: [isNumber, isNotNegative]
  },
  loaderProps: {
    _validator: true,
    _type: {},
    _props: keys('LoaderConfig'),
    color: [isString, isColor],
    size: [isNumber, isNotNegative],
    opacity: [isNumber, isNotNegative],
    style: [isLoaderStyle],
    url: [isString]
  },
  ar3dProps: {
    _validator: true,
    _type: {},
    _props: keys('Ar3dPropsKeys'),
    shadows: [isBoolean],
    showAR: [isBoolean]
  },
  displayProps: validatorsDisplayProps
};
const validate = (configKeys, config, path) => {
  configKeys.forEach(key => {
    const configValidators = path ? validators[path][key] : validators[key];
    const configValue = config[key];
    if (configValidators && !(configValidators instanceof Array) && configValue) {
      if (configValidators._validator) {
        if (Array.isArray(configValidators._type) && !Array.isArray(configValue)) {
          validatorsErrorMsg.push(`${key} must be an array.`);
        } else if (isObj(configValidators._type) && !isObj(configValue)) {
          validatorsErrorMsg.push(`${key} must be an object.`);
        } else if (configValue instanceof Array) {
          configValue.forEach(value => {
            validate(configValidators._props, value, key);
          });
        } else {
          validate(configValidators._props, configValue, key);
        }
      }

      return;
    } else if (configValidators && configValue !== undefined) {
      configValidators.some(
        validator => !validator(configValue, `${path ? `${path}.${key}` : key}`)
      );
    }
  });
};

export default config => {
  validatorsErrorMsg = [];
  validate(keys('ConfigProps'), config);
  const isValid = validatorsErrorMsg.length === 0;
  if (!isValid) {
    const errorMsgTitle = `🌧🌧🌧 Cloudinary Product Gallery Configuration: one or more of your configuration values are not valid: 🌧🌧🌧`;
    const errorMsg = `${validatorsErrorMsg.join('\n')}`;
    if (config.logErrors) {
      console.error(
        `%c${errorMsgTitle} %c${errorMsg}`,
        'background-color: #f44235; color: #FFF;font-weight: bold;padding: 10px; line-height: 1.5; border-bottom: 2px solid #f44235;',
        'background-color: #c6d1db; color: #000;padding: 10px; line-height: 1.5; border-bottom: 2px solid #f44235;'
      );
    } else {
      throw new Error(`${errorMsgTitle}${errorMsg}`);
    }
  }

  return isValid;
};
